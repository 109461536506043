/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import logo from "../Assists/img/logo.png";
import Button from "@mui/material/Button";
import HelpSharpIcon from "@mui/icons-material/HelpSharp";
import { Box, TextField, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useFormik } from "formik";
import * as yup from "yup";
import { useTransition, animated } from "react-spring";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ChartState } from "../Context/Provider";

const validationSchema = yup.object({
  email: yup
    .string("Enter your Votre identfiant")
    .email("Enter a valid Votre identfiant")
    .required("Votre identfiant is required"),
});

export const Landing = () => {
  const nav = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { setEmail, setKg, setHeight } = ChartState();

  setEmail(searchParams.get("email") || "");
  setHeight(searchParams.get("height") || "");
  setKg(searchParams.get("kg") || "");

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      nav("/questions");
    },
  });
  const [show, setShow] = useState(false);
  const [showIcon, setShowIcon] = useState(false);

  const transition = useTransition(show, {
    from: { width: 40, opacity: 0 },
    enter: { width: 200, opacity: 1 },
    leave: { width: 40, opacity: 0 },
  });
  const transitionTwo = useTransition(showIcon, {
    from: { width: 50, opacity: 0 },
    enter: { width: 320, opacity: 1 },
    leave: { width: 40, opacity: 0 },
  });

  const clickHandler = () => {
    nav("/questions");
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          fontSize: "5vw",
          marginTop: "20vh",
        }}
      >
        <Box sx={{ fontWeight: "700", marginBottom: "2rem" }}>
          <Box> Bienvenue sur</Box>

          <img src={logo} width={"80%"} alt="img" />
          <Box sx={{ fontFamily: "jost" }}>
            <Box> Connaissez votre taille de vêtement</Box>
            <Box
              component="span"
              sx={{ fontWeight: "700", marginRight: "5px" }}
            >
              rapidement
            </Box>
            et avec
            <Box component="span" sx={{ fontWeight: "700", marginLeft: "5px" }}>
              précision
            </Box>
          </Box>
          <Button
            variant="contained"
            color="primary"
            sx={{
              margin: "2rem 0 1rem 0",
              padding: "12px 26px",
              fontWeight: "700",
              fontSize: "1rem",
            }}
            onClick={clickHandler}
          >
            C'EST PARTI
          </Button>
          <Box
            sx={{ fontWeight: "700", cursor: "pointer" }}
            onClick={() => setShow((prevVal) => !prevVal)}
          >
            Reprendre mes mesures
          </Box>
        </Box>

        {transition(
          (style, item) =>
            item && (
              <animated.div style={style}>
                <form
                  onSubmit={formik.handleSubmit}
                  style={{ position: "relative" }}
                >
                  <TextField
                    focused
                    placeholder="Votre identfiant"
                    variant="standard"
                    id="email"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    color="primary"
                    sx={{
                      paddingBottom: "5px",
                      position: "relative",
                      input: { paddingBottom: "20px" },
                    }}
                  />
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{
                      minWidth: "33px",
                      padding: "6px 8px",
                      position: "absolute",
                      top: "0",
                      right: "0",
                    }}
                    type="submit"
                  >
                    <ArrowForwardIosIcon />
                  </Button>
                </form>
              </animated.div>
            )
        )}
        <Box
          sx={{
            position: "absolute",
            bottom: "15px",
            left: "18px",
            display: "flex",
            alignItems: "center",
            background: "rgb(0 0 0 / 10%)",
            borderRadius: "30px",
            minHeight: "48px",
          }}
        >
          <HelpSharpIcon
            sx={{
              fontSize: "3.5rem",
              position: "absolute",
              left: "-5px",
            }}
            onClick={() => setShowIcon(!showIcon)}
          />
          {transitionTwo(
            (styletwo, item) =>
              item && (
                <animated.div className="flex" style={styletwo}>
                  <Typography
                    padding="0 0.6rem 0 0.5rem"
                    borderRight="1px solid black"
                    fontSize="0.8rem"
                    fontWeight="700"
                    ml="50px"
                    onClick={() => {
                      window.open("https://www.klipfit.com/#FAQ", "_blank");
                    }}
                  >
                    F.A.Q.
                  </Typography>
                  <Typography
                    padding="0 0.6rem 0 0.4rem "
                    borderRight="1px solid black"
                    fontSize="0.8rem"
                    fontWeight="700"
                    minWidth="90px"
                    onClick={() => {
                      window.open("https://www.klipfit.com/#FAQ", "_blank");
                    }}
                  >
                    Terms of use
                  </Typography>
                  <Typography
                    padding="0 1rem 0 0.4rem"
                    fontSize="0.8rem"
                    fontWeight="700"
                    minWidth="90px"
                    onClick={() => {
                      window.open("https://www.klipfit.com/privacy", "_blank");
                    }}
                  >
                    Privacy Policy
                  </Typography>
                </animated.div>
              )
          )}
        </Box>
      </Box>
    </>
  );
};
