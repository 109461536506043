/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-redeclare */
import React, { useEffect, useState } from "react";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { throttle } from "throttle-debounce";
import { ChartState } from "../Context/Provider";

export const Calibration = () => {
  const nav = useNavigate();
  const [top, setTop] = useState(0);
  const [counter, setCounter] = useState(0);
  const { hasVisited } = ChartState();
  let sub = 0;
  const throttleFunc = throttle(
    1000,
    (num) => {
      setCounter(num);
    },
    { noLeading: true, noTrailing: false }
  );
  useEffect(() => {
    if (!hasVisited) {
      nav("/questions");
    }
    window.addEventListener("deviceorientation", (e) => {
      if (e.beta <= 25) {
        setTop(25);
      } else if (e.beta >= 70) {
        setTop(70);
      } else setTop(e.beta - sub);
    });
  }, []);

  useEffect(() => {
    if (top >= 45 - sub && top < 50 - sub) {
      throttleFunc(counter + 1);
    } else setCounter(0);
  }, [top]);

  if (counter === 6) {
    nav("/go");
  }

  return (
    <>
      <Box
        bgcolor={top >= 45 - sub && top < 50 - sub ? "green" : "white"}
        boxShadow={
          top >= 45 - sub && top < 50 - sub && "0px 0px 0px 100vmax green"
        }
        color={top >= 45 - sub && top < 50 - sub ? "white" : "black"}
        sx={{
          height: "100vh",
          width: "100vw",
          margin: "auto",
          overflowY: "hidden",
        }}
        className="box"
      >
        <Typography fontSize="4vw" fontWeight="700" mt="4rem">
          Aprés avoir posé votre smartphone au sol
        </Typography>
        <Typography fontSize="4vw" fontWeight="700">
          inclinez I'ecran
        </Typography>
        {top >= 45 - sub && top < 50 - sub && (
          <Typography fontSize="6vw" fontWeight="700">
            C'est bon!
          </Typography>
        )}
        <Box display="flex" mt="1rem" justifyContent="center">
          <Typography fontSize="6vw" fontWeight="700">
            {(top < 45 - sub && (
              <Box display="flex" alignItems="center">
                <Box> VERS VOUS</Box>
                <ArrowDownwardIcon
                  sx={{ fill: "blue", fontSize: "2rem", marginLeft: "5px" }}
                />
              </Box>
            )) ||
              (top >= 50 - sub && (
                <Box display="flex" alignItems="center">
                  <Box>VERS LE CIEL</Box>
                  <ArrowUpwardIcon
                    sx={{ fill: "blue", fontSize: "2rem", marginLeft: "5px" }}
                  />
                </Box>
              ))}
          </Typography>
        </Box>
        <Box mt="3rem">
          <Box
            display="flex"
            justifyContent="center"
            sx={{
              position: "absolute",
              top: `${top}%`,
              left: "0",
              right: "0",
            }}
          >
            <Box
              width="100px"
              height="100px"
              bgcolor={top >= 45 - sub && top < 50 - sub ? "green" : "black"}
              border={
                top >= 45 - sub && top < 50 - sub ? "2px solid white" : ""
              }
              borderRadius="50%"
              className="ball"
            ></Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
