import { useMediaQuery } from "react-responsive";

import { Landing } from "./Landing";

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  return isDesktop ? children : null;
};
const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  return isTablet ? children : null;
};
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};

const ScreenCheck = () => (
  <div>
    <Desktop>Scan bar code to open on mobile</Desktop>
    <Tablet>Scan bar code to open on mobile</Tablet>
    <Mobile>
      <Landing />
    </Mobile>
  </div>
);

export default ScreenCheck;
