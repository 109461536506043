/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from "@mui/material";
import React from "react";
import Camera from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import { useState } from "react";
import { Player } from "video-react";
import "../../node_modules/video-react/dist/video-react.css";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ChartState } from "../Context/Provider";

const Go = () => {
  const nav = useNavigate();
  const playerRef = useRef();
  const playerRefTwo = useRef();
  const [render, setRender] = useState("go");
  const [timerShow, setTimerShow] = useState(true);
  const [timerShowTwo, setTimerShowTwo] = useState(true);
  const { hasVisited } = ChartState();

  function handleTakePhoto(dataUri) {
    console.log(dataUri);
  }
  const [timer, setTimer] = useState(6);

  useEffect(() => {
    if (render === "camera" || render === "camera-two") {
      const inner_circle = document.querySelector("#inner-circle");

      var interval = setInterval(() => {
        inner_circle.click();
        if (render === "camera") {
          setTimerShow(false);
        } else {
          setTimerShowTwo(false);
        }
      }, 6000);

      var intervalthree = setInterval(() => {
        setTimer(6);
        if (render === "camera") {
          setRender("video-two");
        } else {
          nav("/calibration");
        }
      }, 9000);
      var intervaltwo = setInterval(() => {
        setTimer((prevVal) => {
          if (prevVal) {
            return prevVal - 1;
          } else return 0;
        });
      }, 1000);
    }
    return () => {
      clearInterval(interval);
      clearInterval(intervaltwo);
      clearInterval(intervalthree);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [render]);

  useEffect(() => {
    if (!hasVisited) {
      nav("/questions");
    }
  }, []);

  return (
    <>
      {render === "go" && (
        <>
          <Box className="custom_box">
            <Camera isFullscreen={false} isImageMirror={true} />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                bottom: "45px",
                fontSize: "1.3rem",
                zIndex: "999999",
                background: "white",
                borderRadius: "50%",
                padding: "1rem",
                fontWeight: "600",
              }}
              onClick={() => {
                playerRefTwo.current.manager.video.play();
                playerRefTwo.current.manager.video.pause();
                playerRef.current.manager.video.play();
                setTimeout(() => {
                  playerRefTwo.current.manager.video.play();
                }, 15000);
                setRender("video-one");
              }}
              variant="contained"
            >
              GO
            </Box>
          </Box>
        </>
      )}
      <Box
        className="c"
        sx={{
          height: "100vh",
        }}
        display={render === "video-one" ? "flex" : "none"}
      >
        <Player
          ref={playerRef}
          playsInline={true}
          muted={false}
          onEnded={() => {
            setRender("camera");
          }}
          src="https://res.cloudinary.com/ddfcndmo7/video/upload/v1663921259/tut-1-ytshorts.savetube.me_m0n0a9.mp4"
        ></Player>
      </Box>

      {render === "camera" && (
        <Box className="custom_box">
          <Box
            sx={{
              position: "absolute",
              right: "0",
              top: "150px",
              height: "100px",
              width: "100px",
              zIndex: "99999999",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              fontSize: "4rem",
              backdropFilter: "blur(30px)",
              display: `${timerShow ? "flex" : "none"}`,
            }}
          >
            {timer}
          </Box>
          <Camera
            onTakePhoto={(dataUri) => {
              handleTakePhoto(dataUri);
            }}
            isImageMirror={true}
          />
        </Box>
      )}

      <Box
        className="c"
        sx={{
          height: "100vh",
        }}
        display={render === "video-two" ? "flex" : "none"}
      >
        <Player
          ref={playerRefTwo}
          playsInline={true}
          muted={false}
          onEnded={() => {
            setRender("camera-two");
          }}
          src="https://res.cloudinary.com/ddfcndmo7/video/upload/v1663921353/tut-2-ytshorts.savetube.me_zkgmhw.mp4"
        ></Player>
      </Box>
      {render === "camera-two" && (
        <Box className="custom_box">
          <Box
            sx={{
              position: "absolute",
              right: "0",
              top: "150px",
              height: "100px",
              width: "100px",
              zIndex: "99999999",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              fontSize: "4rem",
              backdropFilter: "blur(30px)",
              display: `${timerShowTwo ? "flex" : "none"}`,
            }}
          >
            {timer}
          </Box>
          <Camera
            isFullscreen={false}
            onTakePhoto={(dataUri) => {
              handleTakePhoto(dataUri);
            }}
            isImageMirror={true}
          />
        </Box>
      )}
    </>
  );
};

export default Go;
