import { createContext, useContext, useState } from "react";

const ChatContext = createContext();

const Provider = (props) => {
  const [email, setEmail] = useState("");
  const [height, setHeight] = useState("");
  const [kg, setKg] = useState("");
  const [hasVisited, setHasVisited] = useState(false);

  return (
    <ChatContext.Provider
      value={{
        email,
        setEmail,
        height,
        setHeight,
        kg,
        setKg,
        hasVisited,
        setHasVisited,
      }}
    >
      {props.children}
    </ChatContext.Provider>
  );
};

export const ChartState = () => {
  return useContext(ChatContext);
};

export default Provider;
