import React, { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import logo from "../Assists/img/doodle.png";
import { Box } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import "../Styles/CustomStyle.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import Camera from "react-html5-camera-photo";
import Lottie from "react-lottie";
import animationData from "../Assists/Animtion/loading2.json";
import { Player } from "video-react";
import "../../node_modules/video-react/dist/video-react.css";
import { ChartState } from "../Context/Provider";

const validationSchema = yup.object({
  email: yup
    .string("Enter your Votre e-mail")
    .email("Enter a valid Votre e-mail")
    .required("Votre e-mail is required"),
  height: yup
    .number("Enter your Votre taille")
    .min(1)
    .required("Votre taille is required"),
  kg: yup
    .number("Enter your Votre poids")
    .required("Weight is Votre poids")
    .min(1),
});
export const Questions = () => {
  const playerRef = useRef();
  const matches = useMediaQuery("(max-width:390px)");
  const nav = useNavigate();
  const [render, setRender] = useState("questions");
  const { email, height, kg, setHasVisited } = ChartState();
  const formik = useFormik({
    initialValues: {
      email: email,
      height: height,
      kg: kg,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setTimeout(() => {
        playerRef.current.manager.video.play();
      }, 3001);
      if (willSkip) {
        nav("/calibration");
      } else {
        setRender("tutorial");
      }
    },
  });
  const [isActiveFirst, setIsActiveFirst] = useState(true);
  const [isActiveSecond, setIsActiveSecond] = useState(false);
  const [willSkip, setWillSkip] = useState(null);

  const clickHandler = () => {
    setWillSkip(false);
    permission();
  };
  const clickHandlertwo = () => {
    setWillSkip(true);
    permission();
  };

  const permission = () => {
    if (
      typeof DeviceOrientationEvent !== "undefined" &&
      typeof DeviceOrientationEvent.requestPermission === "function"
    ) {
      DeviceOrientationEvent.requestPermission()
        .then((permissionState) => {
          if (permissionState === "granted") {
          }
        })
        .catch(console.error);
    } else {
    }
  };

  const [loading, setLoading] = useState(true);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    if (render === "tutorial") {
      const interval = setInterval(() => {
        setLoading(false);
      }, 3000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [render]);

  useEffect(() => {
    setHasVisited(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box sx={{ display: "none" }}>
        <Camera />
      </Box>
      {render === "questions" && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
          className={matches ? "" : "h-100"}
        >
          <Box
            flex="1"
            width="100%"
            gap="2vw"
            display="flex"
            flexDirection="column"
            mt="2rem"
          >
            <form
              onSubmit={formik.handleSubmit}
              style={{ display: "flex", gap: "2rem", flexDirection: "column" }}
            >
              <Box>
                <TextField
                  focused
                  label="Votre e-mail"
                  placeholder="@"
                  variant="standard"
                  id="email"
                  name="email"
                  value={formik.values.email}
                  onChange={(e) => {
                    formik.handleChange(e);
                    console.log({ values: formik.values });
                  }}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  color="primary"
                  sx={{
                    input: { padding: "1rem 0" },
                    minWidth: "75%",
                  }}
                />
              </Box>

              <Box>
                <TextField
                  focused
                  label="Votre taille"
                  placeholder="En cm"
                  variant="standard"
                  id="height"
                  name="height"
                  type="tel"
                  value={formik.values.height}
                  onChange={formik.handleChange}
                  error={formik.touched.height && Boolean(formik.errors.height)}
                  helperText={formik.touched.height && formik.errors.height}
                  color="primary"
                  sx={{
                    input: { padding: "1rem 0" },
                    minWidth: "75%",
                  }}
                />
              </Box>

              <Box>
                <TextField
                  focused
                  label="Votre poids"
                  placeholder="En kg"
                  variant="standard"
                  margin="normal"
                  color="primary"
                  sx={{
                    input: { padding: "1rem 0" },
                    minWidth: "75%",
                  }}
                  id="kg"
                  name="kg"
                  type="tel"
                  value={formik.values.kg}
                  onChange={formik.handleChange}
                  error={formik.touched.kg && Boolean(formik.errors.kg)}
                  helperText={formik.touched.kg && formik.errors.kg}
                />
              </Box>

              <Box>
                <Box
                  sx={{
                    fontFamily: "jost",
                    display: "flex",
                    width: "75%",
                    margin: "auto",
                    alignItems: "center",
                  }}
                >
                  Vous êtes
                  <Box ml="10px" display="flex">
                    <Button
                      sx={{
                        background: "rgb(0 0 0 / 8%)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "10px",
                        minWidth: "53px",
                        minHeight: "50px",
                      }}
                      className={isActiveFirst && "active"}
                      onClick={() => {
                        setIsActiveFirst(true);
                        setIsActiveSecond(false);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-gender-male"
                        viewBox="0 0 16 16"
                      >
                        <path d="M9.5 2a.5.5 0 0 1 0-1h5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0V2.707L9.871 6.836a5 5 0 1 1-.707-.707L13.293 2H9.5zM6 6a4 4 0 1 0 0 8 4 4 0 0 0 0-8z" />
                      </svg>
                    </Button>
                    <Button
                      sx={{
                        background: "rgb(0 0 0 / 8%)",
                        minWidth: "53px",
                        minHeight: "50px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "10px",
                        marginLeft: "10px",
                        border: "none",
                      }}
                      className={isActiveSecond && "active"}
                      onClick={() => {
                        setIsActiveFirst(false);
                        setIsActiveSecond(true);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-gender-female"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 1a4 4 0 1 0 0 8 4 4 0 0 0 0-8zM3 5a5 5 0 1 1 5.5 4.975V12h2a.5.5 0 0 1 0 1h-2v2.5a.5.5 0 0 1-1 0V13h-2a.5.5 0 0 1 0-1h2V9.975A5 5 0 0 1 3 5z" />
                      </svg>
                    </Button>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Button
                  style={{
                    backgroundColor: "rgb(0 0 0 / 10%)",
                    color: "#0A0A0A",
                    border: "none",
                    borderRadius: "5px",
                    padding: "0.8rem 2rem",
                    fontWeight: "700",
                    cursor: "pointer",
                    maxWidth: "fit-content",
                  }}
                  onClick={clickHandler}
                  type="submit"
                >
                  VALIDER
                </Button>
                <Button
                  sx={{
                    mt: "0.4rem",
                    fontFamily: "jost",
                    fontWeight: "700",
                    maxWidth: "fit-content",
                  }}
                  type="submit"
                  onClick={clickHandlertwo}
                >
                  Passer le tutoriel
                </Button>
              </Box>
            </form>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img src={logo} width="200px" alt="green doodle here" />
          </Box>
        </Box>
      )}
      {render === "tutorial" && (
        <Box>
          <>
            {loading && (
              <Box
                minHeight="100vh"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Lottie
                  options={defaultOptions}
                  width={70}
                  style={{
                    margin: "auto",
                    width: "100px",
                  }}
                />
                )
              </Box>
            )}
            <Box
              className="c"
              sx={{
                height: "100vh",
                display: "flex",
              }}
              display={loading ? "none" : "flex"}
            >
              <Player
                playsInline={true}
                muted={false}
                onEnded={() => {
                  nav("/calibration");
                }}
                ref={playerRef}
                src="https://res.cloudinary.com/ddfcndmo7/video/upload/v1663945157/KlipFit_EN_mov_Google_Drive_p1uf1s_rxnhwh.mp4"
              ></Player>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  nav("/calibration");
                }}
                sx={{
                  position: "absolute",
                  bottom: "70px",
                  left: "0px",
                  fontWeight: "700",
                  display: `${loading ? "none" : ""}`,
                }}
              >
                PASSER
              </Button>
            </Box>
          </>
        </Box>
      )}
    </>
  );
};
