import { Questions } from "./pages/Questions";
import { Calibration } from "./pages/Calibration";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Go from "./pages/Go";
import ScreenCheck from "./pages/ScreenCheck";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<ScreenCheck />} />
          <Route path="/questions" element={<Questions />} />
          <Route path="/calibration" element={<Calibration />} />
          <Route path="/go" element={<Go />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
